import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import {
  getAdminConfigVar,
  Resource,
  Service,
  ServiceBookingGetAvailabilityAPIResponse,
  formatDate
} from '@avicennapharmacy/managemymeds-shared';
import axios, { AxiosResponse } from 'axios';
import Alert from 'react-bootstrap/Alert';
import {
  isAfter,
  isBefore,
  set,
  addDays,
  isSameDay,
  parse,
  isValid,
  getMonth,
  getYear,
  startOfMonth,
  endOfMonth,
  addMonths,
  lastDayOfMonth,
  startOfDay
} from 'date-fns';
import Col from 'react-bootstrap/Col';
import styled from 'styled-components';
import Spinner from 'react-bootstrap/Spinner';
import { InputGroup } from 'react-bootstrap';
import ReactDatePicker from 'react-datepicker';
import Container from 'react-bootstrap/Container';

const DatePickerContainer = styled(Container)`
  width: 100%;
  padding: 0px;
  .react-datepicker__day--outside-month {
    color: transparent !important;
    pointer-events: none !important;
  }
`;

const StyledDatePicker = styled(ReactDatePicker)`
  color: black;
  font-size: 16px;
  background-color: white;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  ::placeholder {
    color: black;
  }
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

const spinner = (
  <div
    style={{
      margin: 10,
      display: 'flex',
      justifyContent: 'center'
    }}
  >
    <Spinner variant="primary" animation="border" role="status" />
  </div>
);

type customHeader = {
  date: Date;
  decreaseMonth: () => void;
  increaseMonth: () => void;
  prevMonthButtonDisabled: boolean;
  nextMonthButtonDisabled: boolean;
};

type NewBookingProps = {
  show: boolean;
  resources: Resource[];
  selectedResourceId: string | null;
  onHide: () => void;
  loadAllBookings: () => void;
};

type GuestBookingRequest = {
  resourceId: string | null;
  serviceId: string;
  firstName: string | undefined;
  lastName: string | undefined;
  email: string | undefined;
  contactNumber: string | undefined;
  startDate: string | undefined;
  dateOfBirth: string | undefined;
  serviceSubType: string | undefined;
};

const defaultBookingRequest = (): GuestBookingRequest => ({
  resourceId: null,
  serviceId: '',
  firstName: undefined,
  lastName: undefined,
  email: undefined,
  contactNumber: undefined,
  startDate: undefined,
  dateOfBirth: undefined,
  serviceSubType: undefined
});

export default ({
  resources,
  selectedResourceId,
  onHide,
  show,
  loadAllBookings
}: NewBookingProps) => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [services, setServices] = useState<Service[]>([]);
  const [servicesLoading, setServicesLoading] = useState(false);
  const [bookingRequest, setBookingRequest] = useState<GuestBookingRequest>(
    defaultBookingRequest()
  );
  const [availability, setAvailability] = useState<ServiceBookingGetAvailabilityAPIResponse | null>(
    null
  );
  const [selectedDay, setSelectedDay] = useState<Date | null>(null);
  const [dd, setDd] = useState<string>('');
  const [mm, setMm] = useState<string>('');
  const [yyyy, setYyyy] = useState<string>('');
  const [fromDate, setFromDate] = useState<Date>(new Date());
  const [toDate, setToDate] = useState<Date>(new Date());
  const [dateFilter, setDateFilter] = useState<Date>(new Date());
  const [selectedService, setSelectedService] = useState<Service | null>(null);
  const [loadMore, setLoadMore] = useState<Boolean>(true);
  const [hasAvailability, setHasAvailability] = useState(false);
  const [lastLoadedMonth, setLastLoadedMonth] = useState<Date | null>(null);
  const [unavailableDates, setUnavailableDates] = useState<Date[]>([]);
  const [disabledDates, setDisabledDates] = useState<Date[]>([]);

  const MAX_RETRIES = 14;

  useEffect(() => {
    const fetchServices = async () => {
      try {
        setServicesLoading(true);
        const url = getAdminConfigVar('serviceBookingGetServicesEndpoint');
        const result = await axios.post<any, AxiosResponse<{ result: Service[] }>>(url);
        setServices(result.data.result);
        setBookingRequest({
          ...bookingRequest,
          resourceId: selectedResourceId
        });
      } catch (err) {
        setError(true);
      } finally {
        setServicesLoading(false);
      }
    };

    if (show && !services.length) fetchServices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedResourceId, show]);

  const getAvailability = async (monthFilter?: Date) => {
    try {
      if (monthFilter && isBefore(monthFilter, fromDate)) {
        monthFilter = fromDate;
      }
      const from = monthFilter ? monthFilter.toISOString() : fromDate.toISOString();

      let isServiceEndDate = false;
      let to = set(lastDayOfMonth(new Date(from)), {
        hours: 23,
        minutes: 59,
        seconds: 59
      });

      if (selectedService?.endDt) {
        const serviceEndDate = new Date(selectedService.endDt);
        setToDate(serviceEndDate);
        if (isAfter(new Date(to), serviceEndDate)) {
          to = serviceEndDate;
          isServiceEndDate = true;
        }
      } else {
        setToDate(new Date());
      }

      if (lastLoadedMonth && isBefore(new Date(from), endOfMonth(lastLoadedMonth))) return;
      setLoading(true);

      const daysOfMonth: string[] = [];

      for (
        let date = new Date(from);
        isBefore(date, isServiceEndDate ? addDays(to, 1) : startOfMonth(addDays(to, 1)));
        date = addDays(date, 1)
      ) {
        daysOfMonth.push(startOfDay(date).toISOString());
      }

      setDisabledDates(daysOfMonth.map((date: string) => new Date(date)));

      const url = getAdminConfigVar('serviceBookingGetAvailabilityEndpoint');
      const res = await axios.post<any, AxiosResponse<ServiceBookingGetAvailabilityAPIResponse>>(
        url,
        {
          resourceId: bookingRequest.resourceId,
          serviceId: bookingRequest.serviceId,
          dateFrom: from,
          dateTo: to.toISOString()
        }
      );

      res.data.days.map(({ availableDate }) => {
        const index = daysOfMonth.indexOf(startOfDay(new Date(availableDate)).toISOString());
        if (index !== -1) daysOfMonth.splice(index, 1);
      });

      unavailableDates.push(...daysOfMonth.map((date: string) => new Date(date)));
      setUnavailableDates(unavailableDates);

      if (lastLoadedMonth && isAfter(new Date(from), lastLoadedMonth)) {
        availability?.availability.push(...res.data.availability);
        availability?.days.push(...res.data.days);

        setAvailability(availability);
      } else {
        setAvailability(res.data);
      }

      setLastLoadedMonth(startOfMonth(new Date(from)));
      if (!hasAvailability) setHasAvailability(!!res.data.days.length);
    } catch {
      setError(true);
    } finally {
      setDisabledDates([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedResourceId && bookingRequest.serviceId) {
      getAvailability();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromDate]);

  useEffect(() => {
    let service = services.filter(s => s.id === bookingRequest.serviceId)[0];
    setHasAvailability(false);
    setLastLoadedMonth(null);
    setAvailability(null);
    setUnavailableDates([]);

    setBookingRequest({
      ...bookingRequest,
      resourceId: selectedResourceId
    });

    if (bookingRequest.resourceId && bookingRequest.serviceId) {
      setAvailability(null);
      setSelectedService(service);
      if (
        !service.hasServiceSubTypes ||
        (service.hasServiceSubTypes && bookingRequest.serviceSubType)
      ) {
        let startDate = getStartDate(service);
        setFromDate(startDate);
        setDateFilter(startDate);
      }
    }
  }, [
    bookingRequest.resourceId,
    bookingRequest.serviceId,
    bookingRequest.serviceSubType,
    services
  ]);

  const getStartDate = (s: Service) => {
    const serviceStartDate = getServiceStartDate(s);
    let startDate = isBefore(new Date(), serviceStartDate) ? serviceStartDate : new Date();
    if (!!s?.daysBookableInAdvance) {
      if (!isBefore(new Date(), serviceStartDate)) {
        startDate = addDays(new Date(), s?.daysBookableInAdvance);
      }
    }
    return startDate;
  };

  const getServiceStartDate = (s: Service) => {
    let serviceStartDate = s.startDt;
    if (s.hasServiceSubTypes) {
      let subTypeIndex = s.subServices!.findIndex(x => x.service === bookingRequest.serviceSubType);
      let subStartDate = s.subServices![subTypeIndex].startDt;
      if (subStartDate) {
        serviceStartDate = subStartDate;
      }
    }
    return serviceStartDate ? new Date(serviceStartDate) : addDays(new Date(), -1);
  };

  const resource = resources.find(r => r.id === selectedResourceId);

  const closeModal = () => {
    setBookingRequest(defaultBookingRequest());
    setDd('');
    setMm('');
    setYyyy('');
    setAvailability(null);
    setSelectedDay(null);
    setSelectedService(null);
    onHide();
  };

  const filterAvailableServices = (s: Service) => {
    const filterStartDate = addDays(
      new Date(),
      s?.daysBookableInAdvance ? s?.daysBookableInAdvance : 0
    );
    return (
      s.active &&
      s.resourceId === selectedResourceId &&
      (!s.endDt || isBefore(filterStartDate, new Date(s.endDt)))
    );
  };

  function isValidEmail(email: string) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const monthChange = (date: Date, compensate: number) => {
    getAvailability(startOfMonth(addMonths(date, compensate)));
  };

  return (
    <>
      <Modal show={show} backdrop="static">
        <Modal.Header>
          <Modal.Title>Add new booking {resource && `- ${resource.name}`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error && <Alert variant="danger">An error has occurred</Alert>}
          <Form>
            {servicesLoading ? (
              <>
                <Form.Label className="mr-3"> Loading Services </Form.Label>
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
              </>
            ) : (
              <Form.Group>
                <Form.Label>
                  Service: {selectedService?.name}
                  <br />
                  {selectedService?.startDt &&
                    `Start date: ${formatDate(selectedService?.startDt, 'date')}`}
                  {selectedService?.endDt &&
                    ` End date: ${formatDate(selectedService?.endDt, 'date')}`}
                  {selectedService?.daysBookableInAdvance && <br /> &&
                    ` DaysBookableInAdvance: ${selectedService.daysBookableInAdvance}`}
                </Form.Label>
                <Form.Control
                  disabled={saving}
                  as="select"
                  value={bookingRequest.serviceId ?? undefined}
                  onChange={(e: any) => {
                    setBookingRequest({
                      ...bookingRequest,
                      serviceId: e.target.value,
                      startDate: undefined,
                      serviceSubType: undefined
                    });
                    setSelectedDay(null);
                  }}
                >
                  <option value="">Select a service...</option>
                  {services
                    .filter(s => filterAvailableServices(s))
                    .map(s => (
                      <option key={s.id} value={s.id}>
                        {s.name}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
            )}
            <Form.Group>
              {selectedService?.hasServiceSubTypes && (
                <Form.Control
                  disabled={saving}
                  as="select"
                  value={bookingRequest.serviceSubType ?? ''}
                  onChange={(e: any) => {
                    setBookingRequest({
                      ...bookingRequest,
                      startDate: undefined,
                      serviceSubType: e.target.value
                    });
                    setSelectedDay(null);
                  }}
                >
                  <option value="">Select a service sub type...</option>
                  {selectedService.subServices?.map(subtype => (
                    <option key={subtype.service} value={subtype.service}>
                      {subtype.service}
                    </option>
                  ))}
                </Form.Control>
              )}
            </Form.Group>
            {!loading && !loadMore && !availability?.days.length && (
              <Form.Label>No more dates available for this service</Form.Label>
            )}
            {selectedService && (
              <Form.Group>
                <Form.Label>
                  Date
                  <br />
                  {`Filtering from: ${formatDate(fromDate, 'date')} to: ${formatDate(
                    toDate,
                    'date'
                  )}`}
                </Form.Label>
                <DatePickerContainer>
                  <StyledDatePicker
                    renderCustomHeader={({
                      date,
                      decreaseMonth,
                      increaseMonth,
                      prevMonthButtonDisabled,
                      nextMonthButtonDisabled
                    }: customHeader) => (
                      <>
                        <div
                          style={{
                            margin: 10,
                            display: 'flex',
                            justifyContent: 'center'
                          }}
                        >
                          <button
                            style={{
                              border: 'none',
                              margin: '0px 30px',
                              fontSize: 16,
                              background: 'none'
                            }}
                            onClick={() => {
                              decreaseMonth();
                              monthChange(date, -1);
                            }}
                            type="button"
                            disabled={prevMonthButtonDisabled}
                          >
                            {'<'}
                          </button>
                          {months[getMonth(date)] + ' ' + getYear(date)}

                          <button
                            style={{
                              border: 'none',
                              margin: '0px 30px',
                              fontSize: 16,
                              background: 'none'
                            }}
                            onClick={() => {
                              increaseMonth();
                              monthChange(date, 1);
                            }}
                            type="button"
                            disabled={nextMonthButtonDisabled}
                          >
                            {'>'}
                          </button>
                        </div>
                        {loading && spinner}
                      </>
                    )}
                    dateFormat="dd/MM/yyyy"
                    disabled={
                      saving ||
                      !selectedService ||
                      (!!selectedService.hasServiceSubTypes && !bookingRequest.serviceSubType)
                    }
                    placeholderText="Select date..."
                    wrapperClassName="container paddingless"
                    showPopperArrow={false}
                    selected={selectedDay}
                    minDate={fromDate}
                    maxDate={toDate}
                    excludeDates={loading ? disabledDates : unavailableDates}
                    onChange={(date: any) => {
                      setBookingRequest({
                        ...bookingRequest,
                        startDate: undefined
                      });
                      setSelectedDay(date);
                    }}
                  />
                </DatePickerContainer>
              </Form.Group>
            )}
            {selectedDay && availability?.availability.length && (
              <Form.Group>
                <Form.Label>Time</Form.Label>
                <Form.Control
                  disabled={saving}
                  as="select"
                  value={bookingRequest.startDate}
                  onChange={(e: any) =>
                    setBookingRequest({
                      ...bookingRequest,
                      startDate: e.target.value
                    })
                  }
                >
                  <option value="">Select a time...</option>
                  {availability?.availability
                    .filter(
                      a => a.isAvailable && isSameDay(new Date(selectedDay), new Date(a.start))
                    )
                    .map(a => (
                      <option key={a.start} value={a.start}>
                        {formatDate(a.start, 'time')}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
            )}
            {bookingRequest.startDate && (
              <>
                <Form.Group>
                  <Form.Label>First name</Form.Label>
                  <Form.Control
                    disabled={saving}
                    type="text"
                    value={bookingRequest.firstName}
                    onChange={(e: any) =>
                      setBookingRequest({ ...bookingRequest, firstName: e.target.value })
                    }
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Last name</Form.Label>
                  <Form.Control
                    disabled={saving}
                    type="text"
                    value={bookingRequest.lastName}
                    onChange={(e: any) =>
                      setBookingRequest({ ...bookingRequest, lastName: e.target.value })
                    }
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Date of birth</Form.Label>
                  <Form.Row>
                    <Col xs={2}>
                      <Form.Control
                        disabled={saving}
                        type="number"
                        value={dd}
                        placeholder="dd"
                        onChange={(e: any) => setDd(e.target.value)}
                      />
                    </Col>
                    <Col xs={2}>
                      <Form.Control
                        disabled={saving}
                        type="number"
                        value={mm}
                        placeholder="mm"
                        onChange={(e: any) => setMm(e.target.value)}
                      />
                    </Col>
                    <Col xs={4}>
                      <Form.Control
                        disabled={saving}
                        type="number"
                        value={yyyy}
                        placeholder="yyyy"
                        onChange={(e: any) => setYyyy(e.target.value)}
                      />
                    </Col>
                  </Form.Row>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Email</Form.Label>
                  <InputGroup>
                    <Form.Control
                      disabled={saving}
                      type="text"
                      value={bookingRequest.email}
                      isInvalid={
                        !isValidEmail(bookingRequest.email ?? '') &&
                        bookingRequest?.email?.length! > 0
                      }
                      onChange={(e: any) =>
                        setBookingRequest({ ...bookingRequest, email: e.target.value })
                      }
                    />
                    <Form.Control.Feedback type="invalid" role="alert">
                      Please enter a valid email address.
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Contact Number</Form.Label>
                  <Form.Control
                    disabled={saving}
                    type="text"
                    value={bookingRequest.contactNumber}
                    onChange={(e: any) =>
                      setBookingRequest({ ...bookingRequest, contactNumber: e.target.value })
                    }
                  />
                </Form.Group>
              </>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => closeModal()}>
            Cancel
          </Button>
          <Button
            onClick={async () => {
              try {
                setSaving(true);
                const url = getAdminConfigVar('serviceBookingCreateGuestBookingEndpoint');
                await axios.post<any>(url, {
                  ...bookingRequest,
                  dateOfBirth: `${yyyy}-${mm}-${dd}`
                });
                await loadAllBookings();
              } catch (err) {
                setError(true);
              } finally {
                setSaving(false);
                closeModal();
              }
            }}
            disabled={
              saving ||
              !bookingRequest.serviceId ||
              !bookingRequest.resourceId ||
              !bookingRequest.startDate ||
              !bookingRequest.firstName ||
              !bookingRequest.lastName ||
              !bookingRequest.email ||
              !bookingRequest.contactNumber ||
              !dd ||
              !mm ||
              !yyyy ||
              !isValid(parse(`${yyyy}-${mm}-${dd}`, 'yyyy-MM-dd', new Date())) ||
              !isValidEmail(bookingRequest.email) ||
              (selectedService?.hasServiceSubTypes === true &&
                !bookingRequest.serviceSubType?.length)
            }
          >
            {saving ? 'Saving...' : 'Add booking'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
