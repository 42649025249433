import React, { SetStateAction, Dispatch } from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import { Service, SubService } from '@avicennapharmacy/managemymeds-shared';
import DatePicker from 'react-datepicker';

const TEXT_MAX_LENGTH = 100;

type ServiceSubTypeProps = {
  validated: boolean;
  service: Service;
  serviceSubType: SubService;
  onServiceChange: Dispatch<SetStateAction<Service | null>>;
  i: number;
};

const ServiceSubType = ({
  validated,
  service,
  serviceSubType,
  onServiceChange,
  i
}: ServiceSubTypeProps) => {
  return (
    <Row key={i} className="mb-1">
      <Col sm={8} xs={8}>
        <Form.Control
          type="text"
          as="textarea"
          rows="1"
          value={serviceSubType.service || ''}
          onChange={(e: any) => {
            const newSubServices = service.subServices?.length ? service.subServices : [];
            newSubServices[i] = {
              ...newSubServices[i],
              service: e.target.value
            };
            onServiceChange({
              ...service,
              subServices: [...newSubServices]
            });
          }}
          required
          isInvalid={!serviceSubType.service.length && validated}
        />
        <Form.Control.Feedback type="invalid">
          Please enter the sub service type to show in dropdown selection, up to {TEXT_MAX_LENGTH}{' '}
          characters.
        </Form.Control.Feedback>
      </Col>
      <Col sm={2} xs={2}>
        <DatePicker
          className="form-control"
          dateFormat="dd/MM/yyyy"
          selected={serviceSubType.startDt ? new Date(serviceSubType.startDt) : undefined}
          onChange={(date: Date) => {
            const newSubServices = service.subServices?.length ? service.subServices : [];
            newSubServices[i] = {
              ...newSubServices[i],
              startDt: date ? new Date(date).toISOString() : ''
            };
            onServiceChange({
              ...service,
              subServices: [...newSubServices]
            });
          }}
        />
      </Col>
      <Col sm={2} xs={2}>
        {service.subServices!.length > 1 && (
          <Button
            variant="danger"
            onClick={() => {
              const serviceSubIndex = service.subServices?.findIndex(
                a => a.service === serviceSubType.service
              );
              onServiceChange({
                ...service,
                subServices: service.subServices?.filter(
                  (ss: SubService, i: number) => i !== serviceSubIndex
                )
              });
            }}
          >
            Remove
          </Button>
        )}
      </Col>
    </Row>
  );
};

export default ServiceSubType;
