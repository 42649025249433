import React, { useState, useEffect } from 'react';
import axios, { AxiosResponse } from 'axios';
import {
  formatDate,
  Service,
  SubService,
  Resource,
  getAdminConfigVar,
  Owner
} from '@avicennapharmacy/managemymeds-shared';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import OpeningHours from './OpeningHours';
import Table from 'react-bootstrap/Table';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import styled from 'styled-components';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SelectFeedback from './SelectFeedback';
import { DeleteServiceResponse } from '../../types/index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  availableAppointmentTypes,
  EXTERNAL_DESCRIPTION_MAX_LENGTH,
  bookingFormTypes
} from '../../config/constants';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import ServiceSubType from './ServiceSubType';

const StyledForm = styled(Form)`
  padding-top: 20px;
  margin-left: 10px;
`;

type ServicesTabProps = {
  owner: Owner;
  selectedPharmacy: any;
  resources: Resource[];
  loading: boolean;
  setLoading: (bool: boolean) => void;
};

type DisplayedService = {
  name: string;
};

export default ({ owner, selectedPharmacy, resources, loading, setLoading }: ServicesTabProps) => {
  const [services, setServices] = useState<Service[]>([]);
  const [displayedServices, setDisplayedServices] = useState<DisplayedService[]>([]);
  const [serviceBeingEdited, setServiceBeingEdited] = useState<Service | null>(null);
  const [, setError] = useState(false);
  const [saving, setSaving] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedPharmacyGroup, setSelectedPharmacyGroup] = useState('');
  const [selectedService, setSelectedService] = useState('');
  const [showDeleteServiceModal, setShowDeleteServiceModal] = useState(false);
  const [showInformationModal, setShowInformationModal] = useState(false);
  const [selectedServiceType, setSelectedServiceType] = useState<string | null>(null);
  const [showServiceLinkModal, setShowServiceLinkModal] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [selectedServiceName, setSelectedServiceName] = useState<string>('');
  const [deleteResponse, setDeleteResponse] = useState<DeleteServiceResponse | null>(null);

  const closeModal = () => {
    setShowModal(false);
    setServiceBeingEdited(null);
  };

  const closeDeleteModal = () => {
    setShowDeleteServiceModal(false);
  };

  const closeInformationModal = () => {
    setShowInformationModal(false);
  };

  const closeLinkModal = () => {
    setShowServiceLinkModal(false);
  };

  const fetchServices = async () => {
    try {
      setLoading(true);
      const url = getAdminConfigVar('serviceBookingGetServicesEndpoint');
      const displayedServicesUrl = getAdminConfigVar('pharmacyServicesEndpoint');
      const [servicesRes, displayedServicesRes] = await Promise.all([
        axios.post(url),
        axios.get(displayedServicesUrl)
      ]);

      setServices(servicesRes.data.result);
      setDisplayedServices(displayedServicesRes.data);
    } catch (err) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (
      serviceBeingEdited != null &&
      (serviceBeingEdited.serviceType === '' || serviceBeingEdited.serviceType === null)
    ) {
      setServiceBeingEdited({
        ...serviceBeingEdited,
        serviceType: 'Online'
      });
    }
  }, [serviceBeingEdited]);

  useEffect(() => {
    fetchServices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <Spinner variant="primary" animation="border" role="status" />;
  }

  return (
    <>
      <h4 className="mb-4 mt-5">Bookable Services</h4>
      <Row className="mb-4">
        <Col>
          <Button
            onClick={() => {
              setServiceBeingEdited({
                id: '',
                rowKey: '',
                groupId: owner.groupId,
                ownerId: owner.id,
                resourceId: resources[0].id,
                name: '',
                externalName: '',
                description: '',
                emailDescription: null,
                externalDescription: null,
                active: true,
                startDt: null,
                endDt: null,
                serviceBookingConfiguration: {
                  bookingTypes: []
                },
                slotDuration: 1,
                daysBookableInAdvance: null,
                openingHours: null,
                pricingInformation: '',
                category: null,
                serviceType: 'Online',
                isMultipleSlot: false,
                patientPerSlot: 1,
                hasServiceSubTypes: false,
                subTypes: [],
                subServices: null
              });
              setShowModal(true);
            }}
          >
            Add new service
          </Button>
        </Col>
      </Row>
      <Table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Active</th>
            <th>Room</th>
            <th>Slot duration</th>
            <th>Available from</th>
            <th>Available until</th>
            <th>External name</th>
            <th>Days bookable in advance</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {services.map(s => {
            const resource: Resource | null = resources.find(r => s.resourceId === r.id) || null;

            return (
              <tr key={s.id}>
                <td>{s.name}</td>
                <td>{String(s.active)}</td>
                <td>{resource?.name}</td>
                <td>
                  {s.slotDuration} ({s.slotDuration * (resource?.slotIntervalMinutes ?? 0)} mins)
                </td>
                <td>{s?.startDt ? formatDate(s.startDt, 'date') : ''}</td>
                <td>{s?.endDt ? formatDate(s.endDt, 'date') : ''}</td>
                <td>{s?.externalName ?? s?.name}</td>
                <td>{s?.daysBookableInAdvance || ''}</td>

                <td style={{ width: '160px' }}>
                  <Button
                    variant="primary"
                    className="mb-2"
                    onClick={() => {
                      setShowModal(true);
                      setServiceBeingEdited(s);
                    }}
                  >
                    Edit
                  </Button>
                  <Button
                    variant="danger"
                    className="ml-2 mb-2"
                    onClick={() => {
                      setShowDeleteServiceModal(true);
                      setSelectedPharmacyGroup(s.groupId);
                      setSelectedService(s.rowKey);
                      setSelectedServiceName(s.name);
                    }}
                  >
                    Delete
                  </Button>
                  <Button
                    variant="secondary"
                    onClick={() => {
                      setShowServiceLinkModal(true);
                      setSelectedService(s.rowKey);
                      setSelectedServiceName(s.name);
                      setSelectedServiceType(s.serviceType);
                    }}
                  >
                    Link
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>

      <EditModal
        show={showModal}
        onHide={closeModal}
        service={serviceBeingEdited}
        resources={resources}
        displayedServices={displayedServices}
        onServiceChange={setServiceBeingEdited}
        loading={saving}
        onSave={async () => {
          try {
            setSaving(true);
            let url = getAdminConfigVar('serviceBookingUpdateServiceEndpoint');
            if (serviceBeingEdited?.id === '') {
              url = getAdminConfigVar('serviceBookingCreateServiceEndpoint');
            }
            await axios.post<any, AxiosResponse<Service[]>>(url, serviceBeingEdited);
            await fetchServices();
          } catch (err) {
            setError(true);
          } finally {
            setSaving(false);
            setError(false);
            closeModal();
          }
        }}
      />

      <DeleteModal
        show={showDeleteServiceModal}
        onHide={closeDeleteModal}
        loading={deleting}
        serviceName={selectedServiceName}
        onConfirm={async () => {
          try {
            setDeleting(true);
            const response: DeleteServiceResponse = (
              await axios.delete(getAdminConfigVar('serviceBookingDeleteServiceEndpoint'), {
                data: {
                  groupId: selectedPharmacyGroup,
                  serviceId: selectedService
                }
              })
            ).data;

            setDeleteResponse(response);
            setShowInformationModal(true);
          } catch (err) {
            setError(true);
          } finally {
            await fetchServices();
            setDeleting(false);
            setError(false);
            setSelectedPharmacyGroup('');
            setSelectedService('');
            closeDeleteModal();
          }
        }}
      />

      <InformationModal
        show={showInformationModal}
        onHide={closeInformationModal}
        status={deleteResponse?.result}
        customMessage={deleteResponse?.showCustomMessage}
        descriptionText={deleteResponse?.message}
      />
      <LinkModal
        show={showServiceLinkModal}
        onHide={closeLinkModal}
        serviceName={selectedServiceName}
        serviceId={selectedService}
        pharmacy={selectedPharmacy}
        serviceType={selectedServiceType}
      />
    </>
  );
};

type EditModalProps = {
  show: boolean;
  onHide: () => void;
  service: Service | null;
  onServiceChange: React.Dispatch<React.SetStateAction<Service | null>>;
  onSave: () => Promise<void>;
  loading: boolean;
  displayedServices: DisplayedService[];
  resources: Resource[];
};
function EditModal({
  show,
  onHide,
  service,
  resources,
  onServiceChange,
  onSave,
  loading,
  displayedServices
}: EditModalProps) {
  const [validated, setValidated] = useState(false);
  const [resource, setResource] = useState<Resource | null>(resources[0]);
  const [useCustomHours, setUseCustomHours] = useState(false);
  const [tabKey, setTabKey] = useState('service');
  const [startDtValidation, setStartDtValidation] = useState(false);
  const [endDtValidation, setEndDtValidation] = useState(false);

  const isExternalDescriptionInvalid =
    Number(service?.externalDescription?.length) > EXTERNAL_DESCRIPTION_MAX_LENGTH;
  const isPatientPerSlotInvalid = Number(service?.patientPerSlot) <= 0;
  const serviceId = service?.id;
  const selectedAppointmentType = availableAppointmentTypes.filter(
    a => service?.serviceBookingConfiguration.bookingTypes.includes(a.value) || false
  );

  const selectedBookingFormType = bookingFormTypes.filter(
    a => a.value === service?.serviceType || false
  );

  useEffect(() => {
    setUseCustomHours(service?.openingHours !== null);
    setResource(resources.find(r => service?.resourceId === r.id) || null);
  }, [service, resources]);

  useEffect(() => {
    setTabKey('service');
  }, [serviceId]);

  useEffect(() => {
    setValidated(false);
  }, [show]);

  return (
    <Modal show={show} onHide={onHide} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Editing: {service?.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {service && (
          <Tabs
            id="edit-service-resource-modal"
            activeKey={tabKey}
            onSelect={(k: string) => setTabKey(k)}
          >
            <Tab eventKey="service" title="Service configuration">
              <StyledForm>
                <h4 className="mt-4">Service details</h4>
                <Form.Group controlId="validationServiceName">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    isInvalid={!service?.name.trim() && validated}
                    type="text"
                    value={service.name}
                    onChange={(e: any) =>
                      onServiceChange({
                        ...service,
                        name: e.target.value
                      })
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter a name for your service
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                  <Form.Label>
                    Sub Service Type selection
                    <br />
                    <small>
                      Tick if the service should have sub service types for selection when booking{' '}
                    </small>
                  </Form.Label>
                  <Form.Check
                    checked={Boolean(service.hasServiceSubTypes)}
                    onChange={() =>
                      onServiceChange({
                        ...service,
                        hasServiceSubTypes: !service.hasServiceSubTypes,
                        subServices: !service.hasServiceSubTypes
                          ? [{ service: '', startDt: '', endDt: '' }]
                          : []
                      })
                    }
                  />
                </Form.Group>
                {service.hasServiceSubTypes && (
                  <>
                    <Form.Group>
                      <Row>
                        <Col sm={12}>
                          <Row>
                            <Col sm={8}>
                              <Form.Label>Sub types for selection</Form.Label>
                            </Col>
                            <Col sm={2}>
                              <Form.Label>Date available from</Form.Label>
                            </Col>
                          </Row>
                          {service.subServices?.map((subService: SubService, i: number) => {
                            return (
                              <ServiceSubType
                                validated={validated}
                                service={service}
                                serviceSubType={subService}
                                onServiceChange={onServiceChange}
                                i={i}
                                key={i}
                              />
                            );
                          })}
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={{ span: 4 }}>
                          <Button
                            disabled={
                              service.subServices?.length
                                ? service.subServices[service.subServices?.length - 1].service
                                    .length < 1
                                : false
                            }
                            onClick={() => {
                              onServiceChange({
                                ...service,
                                subServices: service.subServices?.length
                                  ? [
                                      ...service.subServices,
                                      { service: '', startDt: '', endDt: '' }
                                    ]
                                  : []
                              });
                              setValidated(false);
                            }}
                          >
                            + Add another sub type
                          </Button>
                        </Col>
                      </Row>
                    </Form.Group>
                  </>
                )}
                <Form.Group>
                  <Form.Label>
                    Room
                    <br />
                    <small>This can only be changed for new services.</small>
                  </Form.Label>
                  <Select
                    isDisabled={Boolean(service?.id)}
                    options={resources.map(r => ({
                      value: r.id,
                      label: r.name
                    }))}
                    value={resources
                      .filter(r => r.id === service?.resourceId)
                      .map(r => ({
                        value: r.id,
                        label: r.name
                      }))}
                    onChange={(selected: any) => {
                      if (!selected) {
                        selected = [];
                      }
                      onServiceChange({
                        ...service,
                        resourceId: selected.value
                      });
                    }}
                  />
                  <SelectFeedback
                    isInvalid={!service?.resourceId && validated}
                    feedback="Please select an appointment type"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Available appointment types</Form.Label>
                  <Select
                    options={availableAppointmentTypes}
                    value={selectedAppointmentType}
                    onChange={(selected: any) => {
                      if (!selected) {
                        selected = [];
                      }
                      onServiceChange({
                        ...service,
                        serviceBookingConfiguration: {
                          bookingTypes: [selected.value]
                        }
                      });
                    }}
                  />
                  <SelectFeedback
                    isInvalid={!selectedAppointmentType.length && validated}
                    feedback="Please select an appointment type"
                  />
                </Form.Group>
                <Form.Group>
                  <Row>
                    <Col>
                      <Form.Label>
                        Number of slots
                        <br />
                        <small>
                          {resource && resource?.slotIntervalMinutes > 1 ? (
                            <>
                              The number of {resource?.slotIntervalMinutes ?? 0} minute slots in
                              use. Currently:{' '}
                              {(resource?.slotIntervalMinutes ?? 0) * service.slotDuration} minutes
                            </>
                          ) : (
                            <>The number of minutes for each slot</>
                          )}
                        </small>
                      </Form.Label>
                      <Form.Control
                        type="number"
                        isInvalid={Number(!service?.slotDuration) > 0 && validated}
                        value={String(service.slotDuration)}
                        onChange={(e: any) =>
                          onServiceChange({
                            ...service,
                            slotDuration: Number(e.target.value)
                          })
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Slot duration should be higher than 0.
                      </Form.Control.Feedback>
                    </Col>
                    <Col>
                      <Form.Label>
                        Days bookable in advance
                        <br />
                        <small>
                          If not supplied, patients will be able to book appointments on the same
                          day
                        </small>
                      </Form.Label>
                      <Form.Control
                        type="number"
                        value={String(service.daysBookableInAdvance)}
                        onChange={(e: any) =>
                          onServiceChange({
                            ...service,
                            daysBookableInAdvance: Number(e.target.value)
                          })
                        }
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row>
                    <Col sm={6}>
                      <Form.Label>Date available from</Form.Label>
                      <DatePicker
                        wrapperClassName="container leftpaddingless"
                        className="form-control"
                        dateFormat="dd/MM/yyyy"
                        selected={service.startDt ? new Date(service.startDt) : undefined}
                        onChange={(date: Date) => {
                          if (date) {
                            setStartDtValidation(true);
                            onServiceChange({
                              ...service,
                              startDt: date.toISOString()
                            });
                          } else {
                            setStartDtValidation(false);
                            onServiceChange({
                              ...service,
                              startDt: null
                            });
                          }
                        }}
                      />
                      {!startDtValidation && service?.startDt === null && validated && (
                        <Form.Label style={{ fontSize: '80%', color: '#d44b40' }}>
                          Please enter a start date.
                        </Form.Label>
                      )}
                    </Col>
                    <Col sm={6}>
                      <Form.Label>Date available until</Form.Label>
                      <DatePicker
                        wrapperClassName="container leftpaddingless"
                        className="form-control"
                        dateFormat="dd/MM/yyyy"
                        selected={service.endDt ? new Date(service.endDt) : undefined}
                        onChange={(date: Date) => {
                          if (date) {
                            setEndDtValidation(true);
                            onServiceChange({
                              ...service,
                              endDt: date.toISOString()
                            });
                          } else {
                            setEndDtValidation(false);
                          }
                        }}
                      />
                      {!endDtValidation && service?.endDt === null && validated && (
                        <Form.Label style={{ fontSize: '80%', color: '#d44b40' }}>
                          Please enter an end date.
                        </Form.Label>
                      )}
                    </Col>
                  </Row>
                </Form.Group>
                <Row className="my-3">
                  <Button variant="link" onClick={() => setTabKey('opening')}>
                    Set opening times
                  </Button>
                </Row>
                <Form.Group>
                  <Form.Label>
                    Active
                    <br />
                    <small>
                      Tick if the service should be live (respects available dates set above)
                    </small>
                  </Form.Label>
                  <Form.Check
                    checked={service.active}
                    onChange={() =>
                      onServiceChange({
                        ...service,
                        active: !service.active
                      })
                    }
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>
                    AviClinic
                    <br />
                    <small>Tick if the service should be available in the AviClinic category</small>
                  </Form.Label>
                  <Form.Check
                    checked={Boolean(service.category)}
                    onChange={() =>
                      onServiceChange({
                        ...service,
                        category: service.category ? null : 'AviClinic'
                      })
                    }
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Service Type</Form.Label>
                  <Select
                    options={bookingFormTypes}
                    value={selectedBookingFormType}
                    onChange={(selected: any) => {
                      onServiceChange({
                        ...service,
                        serviceType: selected.value
                      });
                    }}
                  />
                </Form.Group>
                <Row>
                  <Col sm={6}>
                    <Form.Group>
                      <Form.Label>
                        Multiple Bookable
                        <br />
                        <small>
                          Tick if the service slots are available for multiple bookings.
                        </small>
                      </Form.Label>
                      <Form.Check
                        checked={service.isMultipleSlot === true ? true : false}
                        onChange={() => {
                          if (service.isMultipleSlot === true) {
                            onServiceChange({
                              ...service,
                              isMultipleSlot: !service.isMultipleSlot,
                              patientPerSlot: 0
                            });
                          } else {
                            onServiceChange({
                              ...service,
                              isMultipleSlot: !service.isMultipleSlot
                            });
                          }
                        }}
                      />
                    </Form.Group>
                  </Col>
                  {service.isMultipleSlot && (
                    <>
                      <Col sm={6}>
                        <Form.Group>
                          <Form.Label>Allowed patient per slot</Form.Label>
                          <Form.Control
                            isInvalid={isPatientPerSlotInvalid}
                            type="number"
                            min="1"
                            value={service.patientPerSlot?.toString()}
                            onChange={(e: any) => {
                              onServiceChange({
                                ...service,
                                patientPerSlot: e.target.value
                              });
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Allowed patient per slot should be at least 1.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </>
                  )}
                </Row>

                <h4 className="mt-5">External information</h4>
                <p>The details below will be displayed to the patients</p>
                <Form.Group>
                  <Form.Label>External name</Form.Label>
                  <Form.Control
                    type="text"
                    isInvalid={!service?.externalName?.trim() && validated}
                    value={service.externalName ?? undefined}
                    onChange={(e: any) =>
                      onServiceChange({
                        ...service,
                        externalName: e.target.value
                      })
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter an external name for your service
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                  <Form.Label>
                    External description
                    <br />
                    <small>
                      Provide an overview of the service and how the appointment will take place,
                      any additional requirements etc.
                    </small>
                  </Form.Label>
                  <Form.Control
                    isInvalid={isExternalDescriptionInvalid}
                    as="textarea"
                    rows={5}
                    value={service.externalDescription ?? undefined}
                    onChange={(e: any) =>
                      onServiceChange({
                        ...service,
                        externalDescription: e.target.value
                      })
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide an external description for your customers that is less than{' '}
                    {EXTERNAL_DESCRIPTION_MAX_LENGTH} characters.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                  <Form.Label>
                    Pricing information
                    <br />
                    <small>
                      Free text for description of any costs the patient may have to incur
                    </small>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    value={service.pricingInformation ?? undefined}
                    onChange={(e: any) =>
                      onServiceChange({
                        ...service,
                        pricingInformation: e.target.value
                      })
                    }
                  />
                </Form.Group>
                <Form.Group>
                  <Row>
                    <Col sm={6}>
                      <Form.Label>External Link</Form.Label>
                      <Form.Control
                        type="text"
                        value={service.externalLink ?? undefined}
                        onChange={(e: any) =>
                          onServiceChange({
                            ...service,
                            externalLink: e.target.value
                          })
                        }
                      />
                    </Col>
                    <Col sm={6}>
                      <Form.Label>External Link Button Text</Form.Label>
                      <Form.Control
                        type="text"
                        value={service.externalLinkText ?? undefined}
                        onChange={(e: any) =>
                          onServiceChange({
                            ...service,
                            externalLinkText: e.target.value
                          })
                        }
                      />
                    </Col>
                  </Row>
                </Form.Group>
              </StyledForm>
            </Tab>
            <Tab eventKey="opening" title="Service opening times">
              <StyledForm>
                <Form.Check
                  type="radio"
                  id="resource-hours"
                  label="Use room hours"
                  checked={!useCustomHours}
                  onChange={() => {
                    setUseCustomHours(!useCustomHours);
                    onServiceChange({
                      ...service,
                      openingHours: null
                    });
                  }}
                />
                <Form.Check
                  type="radio"
                  id="custom-hours"
                  label="Use custom hours"
                  checked={useCustomHours}
                  onChange={() => {
                    setUseCustomHours(!useCustomHours);
                    onServiceChange({
                      ...service,
                      openingHours: []
                    });
                  }}
                />
              </StyledForm>
              {useCustomHours && (
                <OpeningHours
                  openingHours={service?.openingHours}
                  onChange={onServiceChange as any}
                />
              )}
            </Tab>
          </Tabs>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide} disabled={loading}>
          Cancel
        </Button>
        <Button
          active={!loading}
          onClick={async () => {
            setValidated(true);
            if (
              (!startDtValidation && service?.startDt === null) ||
              (!endDtValidation && service?.endDt === null) ||
              !service?.name.trim() ||
              !selectedAppointmentType.length ||
              (service.hasServiceSubTypes &&
                !service.subServices?.every(st => st.service.trim().length)) ||
              !service?.externalName?.trim()
            ) {
              return;
            }

            await onSave();
          }}
          disabled={
            loading ||
            isExternalDescriptionInvalid ||
            (service?.isMultipleSlot == true && isPatientPerSlotInvalid)
          }
        >
          {loading ? 'Loading...' : 'Save'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

type DeleteModalProps = {
  show: boolean;
  onHide: () => void;
  onConfirm: () => Promise<void>;
  loading: boolean;
  serviceName: string;
};
function DeleteModal({ show, onHide, onConfirm, loading, serviceName }: DeleteModalProps) {
  return (
    <Modal show={show} onHide={onHide} centered={true} className="text-center">
      <Modal.Header closeButton>
        <Modal.Title>
          Removing: <strong>{serviceName}</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <>
            <FontAwesomeIcon icon="trash-alt" size="6x" color="#0f5d5e" />
            <br />
            <br />
            <strong>Please Wait</strong>
            <p>Your service is removing.</p>
          </>
        ) : (
          <>
            <FontAwesomeIcon icon="question-circle" size="6x" color="#0f5d5e" />
            <br />
            <br />
            <strong>Are you sure?</strong>
            <p>
              If you confirm this popup, this service will be removed and it will not be possible to
              get a booking for this service again.
            </p>
            <br />
            <strong>Note:</strong>
            <p>
              If you have any upcoming booking for this service, you have to cancel them before
              removing the service
            </p>
          </>
        )}
      </Modal.Body>
      {!loading ? (
        <>
          <Modal.Footer>
            <Button variant="secondary" onClick={onHide} disabled={loading}>
              Cancel
            </Button>
            <Button
              variant="danger"
              active={!loading}
              onClick={async () => {
                await onConfirm();
              }}
              disabled={loading}
            >
              {loading ? 'Loading...' : 'Confirm'}
            </Button>
          </Modal.Footer>
        </>
      ) : (
        <></>
      )}
    </Modal>
  );
}

type InformationModalProps = {
  show: boolean;
  onHide: () => void;
  status: boolean | null | undefined;
  customMessage: boolean | null | undefined;
  descriptionText: string | null | undefined;
};
function InformationModal({
  show,
  onHide,
  status,
  customMessage,
  descriptionText
}: InformationModalProps) {
  return (
    <Modal show={show} onHide={onHide} centered={true} className="text-center">
      <Modal.Header closeButton>
        <Modal.Title>{status ? 'Completed' : 'Error'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {customMessage ? (
          <>
            {status ? (
              <>
                <FontAwesomeIcon icon="check-circle" size="6x" color="#0f5d5e" />
              </>
            ) : (
              <>
                <FontAwesomeIcon icon="times-circle" size="6x" color="#0f5d5e" />
              </>
            )}
            <br />
            <br />
            <p>{descriptionText}</p>
          </>
        ) : (
          <>
            <FontAwesomeIcon icon="times-circle" size="6x" color="#0f5d5e" />
            <br />
            <br />
            <strong>Error</strong>
            <p>Service could not remove. Please try again later.</p>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
}

type LinkModalProps = {
  show: boolean;
  onHide: () => void;
  serviceName: string;
  serviceId: string;
  pharmacy: any;
  serviceType: string | null | undefined;
};
function LinkModal({
  show,
  onHide,
  serviceName,
  serviceId,
  pharmacy,
  serviceType
}: LinkModalProps) {
  const [copied, setCopied] = useState(false);

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 500);
    });
  };
  return (
    <Modal show={show} onHide={onHide} centered={true} className="text-center">
      <Modal.Header closeButton>
        <Modal.Title>
          <strong>{serviceName}</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <>
          {serviceType === 'PersonalData' ? (
            <>
              <strong>To access this form, please use the following URL.</strong>
              <br />
              <br />
              <p>
                {`${process.env.REACT_APP_LANDING_PERSONALDATA}?pharmacy=${pharmacy.id}&service=${serviceId}`}
              </p>
              <p
                onClick={() => {
                  copyToClipboard(
                    `${process.env.REACT_APP_LANDING_PERSONALDATA}?pharmacy=${pharmacy.id}&service=${serviceId}`
                  );
                }}
              >
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip id="tooltip-clipboard">
                      {copied ? 'Copied!' : 'Copy to clipboard'}
                    </Tooltip>
                  }
                >
                  <Button variant="link">
                    <FontAwesomeIcon icon="clipboard" />
                  </Button>
                </OverlayTrigger>
              </p>
            </>
          ) : (
            <>
              <strong>Link to book this service on landing site:</strong>
              <br />
              <br />
              <p>
                {`${process.env.REACT_APP_LANDING_SERVICES}?pharmacy=${pharmacy.id}&service=${serviceId}`}
              </p>
              <p
                onClick={() => {
                  copyToClipboard(
                    `${process.env.REACT_APP_LANDING_SERVICES}?pharmacy=${pharmacy.id}&service=${serviceId}`
                  );
                }}
              >
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip id="tooltip-clipboard">
                      {copied ? 'Copied!' : 'Copy to clipboard'}
                    </Tooltip>
                  }
                >
                  <Button variant="link">
                    <FontAwesomeIcon icon="clipboard" />
                  </Button>
                </OverlayTrigger>
              </p>
            </>
          )}
        </>
      </Modal.Body>
    </Modal>
  );
}
