import { createSelector } from 'reselect';
import { GlobalState } from '../reducers';

export const getUsername = (state: GlobalState) => state.user.username;
export const getPharmacies = (state: GlobalState) => state.user.pharmacies;
export const getSelectedPharmacyID = (state: GlobalState) => state.user.selectedPharmacy;
export const getGroups = (state: GlobalState) => state.user.groups;
export const getSelectedGroupID = (state: GlobalState) => state.user.selectedGroup;
export const getOwner = (state: GlobalState) => state.user.owner;
export const getLoadedOwner = (state: GlobalState) => state.user.loadedOwner;

export const getSelectedPharmacy = createSelector(
  [getPharmacies, getSelectedPharmacyID],
  (pharmacies, id) => (id ? pharmacies.find(p => p.id === id) : null)
);

export const getSelectedGroup = createSelector([getGroups, getSelectedGroupID], (groups, id) =>
  id ? groups.find(g => g.id === id) : null
);
