import { connect } from 'react-redux';
import { GlobalState } from '../../reducers';
import { updateOwner } from '../../actions/user';
import { getSelectedPharmacy, getOwner } from '../../selectors/user';
import { getUserPharmacies } from '../../actions/user';
import ServiceEdit from '.';
import { Owner } from '@avicennapharmacy/managemymeds-shared';

const mapStateToProps = (state: GlobalState) => ({
  pharmacy: getSelectedPharmacy(state),
  owner: getOwner(state)
});

const mapDispatchToProps = (dispatch: any) => ({
  updateOwner: async (owner: Owner | null) => {
    dispatch(await updateOwner(owner));
  },
  getUserPharmacies: () => dispatch(getUserPharmacies())
});

export default connect(mapStateToProps, mapDispatchToProps)(ServiceEdit);
