import { connect } from 'react-redux';
import DiaryPage from './DiaryPage';
import { GlobalState } from '../../reducers';
import {
  setLoadingBookings,
  setLoadingBookingsComplete,
  loadResources,
  resetDiary,
  loadBookingsForResource,
  setSelectedResource,
  setLoadedPharmacy,
  setLastLoadedMonth,
  clearSelectedBookings
} from '../../actions/bookings';
import { getSelectedPharmacy } from '../../selectors/user';
import {
  getBookings,
  getSelectedBookings,
  getSelectedResource,
  getLoadedPharmacy,
  getResources,
  getlastLoadedMonth
} from '../../selectors/bookings';

const mapStateToProps = (state: GlobalState) => ({
  selectedPharmacy: getSelectedPharmacy(state),
  selectedResource: getSelectedResource(state),
  loadedPharmacy: getLoadedPharmacy(state),
  resources: getResources(state),
  loadingBookings: state.bookings.loadingBookings,
  bookings: getBookings(state),
  selectedBookings: getSelectedBookings(state),
  lastLoadedMonth: getlastLoadedMonth(state)
});

const mapDispatchToProps = (dispatch: any) => ({
  setLoadingBookings: () => {
    dispatch(setLoadingBookings());
  },
  setLoadingBookingsComplete: async () => {
    dispatch(await setLoadingBookingsComplete());
  },
  loadResources: async () => {
    dispatch(setLoadingBookings());
    dispatch(await loadResources());
  },
  resetDiary: async () => {
    dispatch(await resetDiary());
  },
  loadBookingsForResource: async (
    resourceId: string,
    pharmacyId: string,
    fromDate: any,
    toDate: any,
    clear: boolean,
    callback?: Function
  ) => {
    dispatch(setLoadingBookings());
    dispatch(
      await loadBookingsForResource({
        resourceId,
        pharmacyId,
        fromDate,
        toDate,
        clear
      })
    );
    callback && callback();
  },
  setSelectedResource: async (id: string) => {
    dispatch(await setSelectedResource(id));
  },
  setLoadedPharmacy: async (id: string) => {
    dispatch(await setLoadedPharmacy(id));
  },
  setLastLoadedMonth: async (month: string) => {
    dispatch(await setLastLoadedMonth(month));
  },
  clearSelectedBookings: () => dispatch(clearSelectedBookings())
});

export default connect(mapStateToProps, mapDispatchToProps)(DiaryPage);
