import { connect } from 'react-redux';
import PrivateRoute from './PrivateRoute';
import { GlobalState } from '../reducers';
import {
  getUsername,
  getPharmacies,
  getSelectedPharmacy,
  getGroups,
  getSelectedGroup,
  getOwner
} from '../selectors/user';
import { setSelectedPharmacy, setSelectedGroup } from '../actions/user';

const mapStateToProps = (state: GlobalState) => ({
  username: getUsername(state),
  pharmacies: getPharmacies(state),
  selectedPharmacy: getSelectedPharmacy(state),
  groups: getGroups(state),
  selectedGroup: getSelectedGroup(state),
  owner: getOwner(state),
  pharmacy: getSelectedPharmacy(state)
});

const mapDispatchToProps = (dispatch: any) => ({
  setSelectedPharmacy: (id: string) => dispatch(setSelectedPharmacy(id)),
  setSelectedGroup: (id: string) => dispatch(setSelectedGroup(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
