import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { getAdminConfigVar, Owner, Pharmacy, Group } from '@avicennapharmacy/managemymeds-shared';
import axios, { AxiosResponse } from 'axios';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';

type GeneralTabProps = {
  owner: Owner | null;
  updateOwner: (owner: Owner | null) => void;
  pharmacy: Pharmacy | null | undefined;
  getUserPharmacies: () => { pharmacies: Pharmacy[]; groups: Group[] };
};

export default ({ owner, updateOwner, pharmacy, getUserPharmacies }: GeneralTabProps) => {
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(false);
  const [isEnabled, setIsEnabled] = useState(false);

  useEffect(() => {
    setIsEnabled(owner ? owner.active : false);
  }, [owner]);

  return (
    <>
      <h4 className="mb-4 mt-5">General configuration</h4>
      {error && (
        <Alert variant="danger">An error has occurred. Please contact your Administrator</Alert>
      )}
      {isEnabled && (
        <>
          <p>
            Use the <strong>services</strong> tab to define which services are available to your
            users and when they can be booked.
          </p>
          <p>Turning off this functionality will not cancel any existing bookings.</p>
        </>
      )}
      {!isEnabled && owner && (
        <p>
          Turning on this functionality will allow users of the app to book services at your
          pharmacy.
        </p>
      )}
      {!isEnabled && !owner && <p>Service booking unavailable, please contact the administrator</p>}
      {owner && (
        <Form>
          <Form.Group as={Row} className="mt-5">
            <Col sm={2}>
              <Form.Label>Service booking enabled</Form.Label>
            </Col>
            <Col>
              <Form.Check checked={isEnabled} onChange={() => setIsEnabled(!isEnabled)} />
            </Col>
          </Form.Group>
          <Row>
            <Col>
              <Button
                active={!saving}
                onClick={async () => {
                  let res = null;
                  try {
                    setSaving(true);
                    res = await axios.post<any, AxiosResponse<Owner>>(
                      getAdminConfigVar('serviceBookingUpdateOwnerEndpoint'),
                      {
                        active: isEnabled,
                        groupId: pharmacy?.groupId
                      }
                    );
                  } catch {
                    setError(true);
                  } finally {
                    setSaving(false);
                    if (owner && res?.status === 200) {
                      owner.active = isEnabled;
                    }
                    updateOwner(owner ?? null);
                    getUserPharmacies();
                  }
                }}
                disabled={saving || isEnabled === owner?.active}
              >
                {saving ? 'Saving...' : 'Save'}
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </>
  );
};
