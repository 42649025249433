import { connect } from 'react-redux';
import {
  signIn,
  getUserPharmacies,
  setPharmacies,
  setSelectedPharmacy,
  setGroups,
  setSelectedGroup
} from '../actions/user';
import SignIn from './SignIn';

const mapDispatchToProps = (dispatch: any) => ({
  signIn: (username: string) => dispatch(signIn(username)),
  setPharmacies: (pharmacies: any[]) => dispatch(setPharmacies(pharmacies)),
  setSelectedPharmacy: (id: string) => dispatch(setSelectedPharmacy(id)),
  setGroups: (groups: any[]) => dispatch(setGroups(groups)),
  setSelectedGroup: (id: string) => dispatch(setSelectedGroup(id)),
  getUserPharmacies: () => dispatch(getUserPharmacies())
});

export default connect(() => ({}), mapDispatchToProps)(SignIn);
