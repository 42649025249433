import { combineReducers } from 'redux';
import bookingsReducer, { bookingsStateType } from './bookings';
import ordersReducer, { ordersStateType } from './orders';
import userReducer, { userStateType } from './user';

export default combineReducers({
  bookings: bookingsReducer,
  orders: ordersReducer,
  user: userReducer
});

export type GlobalState = {
  bookings: bookingsStateType;
  orders: ordersStateType;
  user: userStateType;
};
