import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import styled from 'styled-components';
import logo from '../assets/logo.png';
import {
  getAdminConfigVar,
  SignInType,
  Pharmacy,
  Group
} from '@avicennapharmacy/managemymeds-shared';
import { appInsights } from '..';
import {
  addAxiosAuthInterceptors,
  addAxiosPharmacyInterceptors,
  storeAccessToken,
  storeRefreshToken
} from '../utils/auth';
import qs from 'qs';
import Alert from 'react-bootstrap/Alert';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';

type SignInProps = {
  signIn: (username: string) => void;
  setPharmacies: (pharmacies: any[]) => void;
  setSelectedPharmacy: (id: string) => void;
  setGroups: (groups: any[]) => void;
  setSelectedGroup: (id: string) => void;
  getUserPharmacies: () => { pharmacies: Pharmacy[]; groups: Group[] };
};

export default ({
  signIn,
  setPharmacies,
  setSelectedPharmacy,
  setGroups,
  setSelectedGroup,
  getUserPharmacies
}: SignInProps) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [validated, setValidated] = useState(false);
  const [signInFail, setSignInFail] = useState(false);
  const [signInLoading, setSignInLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const fromBackOffice = qs.parse(window.location.search, { ignoreQueryPrefix: true })
    .fromBackOffice;

  const handleSubmit = async (event: any) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      setSignInLoading(true);
      try {
        event.preventDefault();
        event.stopPropagation();

        const res = await axios.post(getAdminConfigVar('signInEndpoint'), {
          username,
          password,
          signInType: SignInType.Portal
        });

        if (!res.data?.access_token || !res.data?.refresh_token) {
          throw Error('unable to retrieve tokens');
        }

        storeAccessToken(res.data.access_token);
        storeRefreshToken(res.data.refresh_token);
        await addAxiosAuthInterceptors();

        var userPharmacies = await getUserPharmacies();

        if (userPharmacies.pharmacies?.length === 1 && userPharmacies.groups.length === 0) {
          setSelectedPharmacy(userPharmacies.pharmacies[0].id);
        } else if (
          (!userPharmacies.pharmacies ||
            !Array.isArray(userPharmacies.pharmacies) ||
            !userPharmacies.pharmacies.length) &&
          (!userPharmacies.groups ||
            !Array.isArray(userPharmacies.groups) ||
            !userPharmacies.groups.length)
        ) {
          throw Error('No pharmacies connected to user');
        }

        await addAxiosPharmacyInterceptors();

        appInsights.trackEvent({ name: 'Sign in', properties: { username } });
        signIn(username);
        setSignInLoading(false);

        // Remove query param
        if (fromBackOffice) {
          window.location.href = `${window.location.origin}/${window.location.hash}`;
        }
      } catch (e) {
        console.warn(e);
        appInsights.trackEvent({ name: 'Sign in failure', properties: { username } });
        appInsights.trackException(e);
        setSignInLoading(false);
        setSignInFail(true);

        event.preventDefault();
        event.stopPropagation();
      }
    }
    setValidated(true);
  };

  return (
    <SignInForm noValidate validated={validated} onSubmit={async (e: any) => await handleSubmit(e)}>
      <Logo src={logo} height={60} width={312} alt="Avicenna Logo" />
      {fromBackOffice === 'True' && (
        <Alert variant="primary">Account created - sign into your store's portal</Alert>
      )}
      <Form.Group>
        <Form.Label>Email address</Form.Label>
        <Form.Control
          required
          type="email"
          placeholder="Enter email"
          value={username}
          onChange={(e: any) => setUsername(e.target.value)}
        />
        <Form.Control.Feedback type="invalid">Please enter your email</Form.Control.Feedback>
      </Form.Group>
      <Form.Group>
        <Form.Label>Password</Form.Label>
        <Form.Control
          required
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e: any) => setPassword(e.target.value)}
        />
        <Form.Control.Feedback type="invalid">Please enter your password</Form.Control.Feedback>
      </Form.Group>
      {signInFail && <p>Incorrect username and/or password. Please try again.</p>}
      <Row>
        <Col sm={6}>
          <Button variant="primary" type="submit" disabled={signInLoading}>
            {signInLoading ? 'Signing in...' : 'Sign In'}
          </Button>
        </Col>
        <Col sm={6} className="text-right">
          <Button variant="link" size="sm" onClick={() => setShowModal(true)}>
            Reset password
          </Button>
        </Col>
      </Row>
      <ResetPasswordModal showModal={showModal} setShowModal={setShowModal} username={username} />
    </SignInForm>
  );
};

type ResetPasswordModalProps = {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
  username: string;
};

const ResetPasswordModal = ({ showModal, username, setShowModal }: ResetPasswordModalProps) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [currentUsername, setCurrentUsername] = useState(username);

  // Update username when modal re-opened
  useEffect(() => {
    setCurrentUsername(username);
  }, [showModal]);

  return (
    <Modal
      show={showModal}
      onHide={() => {
        setShowModal(false);
        setLoading(false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Reset Password</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error && (
          <Alert variant="danger">There was an error sending the reset password email</Alert>
        )}
        <p>Send reset password instructions to:</p>
        <Form.Control
          required
          type="email"
          placeholder="Enter email"
          value={currentUsername}
          onChange={(e: any) => setCurrentUsername(e.target.value)}
        />
        <p></p>
        <p>The link in your email will be valid for 24 hours.</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShowModal(false)}>
          Cancel
        </Button>
        {loading ? (
          <Button variant="primary" disabled>
            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
            <span className="sr-only">Sending...</span>
          </Button>
        ) : (
          <Button
            variant="primary"
            onClick={async () => {
              try {
                setLoading(true);
                let url = getAdminConfigVar('resetPasswordEndpoint');
                await axios.post(url, {
                  email: currentUsername,
                  platform: 'web',
                  redirectURL: window.origin
                });
                setShowModal(false);
              } catch (e) {
                setError(true);
              }
              setLoading(false);
            }}
          >
            Send instructions
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

const SignInForm = styled(Form)`
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
  margin-top: 8vh;
`;

const Logo = styled.img`
  margin-left: -10px;
  margin-bottom: 40px;
`;
