import axios from 'axios';
import { store } from '../index';
import { signOut } from '../actions/user';
import { getSelectedPharmacy } from '../selectors/user';
import { getAdminConfigVar } from '@avicennapharmacy/managemymeds-shared';

export async function addAxiosAuthInterceptors() {
  // Add access token to all requests
  axios.interceptors.request.use(async request => {
    request.headers.Authorization = `Bearer ${sessionStorage.getItem('access_token')}`;
    return request;
  });

  axios.interceptors.response.use(
    response => {
      return response;
    },
    async error => {
      if (error.config.data) error.config.data = JSON.parse(error.config.data);
      const originalRequest = error.config;

      if (
        !error ||
        !error.response ||
        (error.response.status === 401 &&
          originalRequest.url === getAdminConfigVar('refreshTokenEndpoint'))
      ) {
        store.dispatch(signOut());
        return Promise.reject(error);
      }

      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        const refresh_token = sessionStorage.getItem('refresh_token');

        const res = await axios.post(getAdminConfigVar('refreshTokenEndpoint'), {
          refresh_token
        });

        if (res.status === 200) {
          // Update tokens in stores
          storeAccessToken(res.data.access_token);
          storeRefreshToken(res.data.refresh_token);
          axios.defaults.headers.common.Authorization = 'Bearer ' + res.data.access_token;
          return axios(originalRequest);
        }
      }
      return Promise.reject(error);
    }
  );
}

export async function addAxiosPharmacyInterceptors() {
  // Add access token to all requests
  axios.interceptors.request.use(async request => {
    request.data = {
      ...request.data,
      externalOwnerId: store.getState().user.selectedPharmacy,
      ownerId: store.getState().user.owner?.ownerId,
      groupId: request.data?.groupId ? request.data.groupId : store.getState().user.owner?.groupId,
      externalGroupId: getSelectedPharmacy(store.getState())?.groupId ?? null,
      externalId: store.getState().user.selectedPharmacy,
      pharmacyId: store.getState().user.selectedPharmacy,
      currentlySelectedGroupId: store.getState().user.selectedGroup
    };
    return request;
  });
}

export async function storeAccessToken(access_token: string) {
  sessionStorage.setItem('access_token', access_token);
}

export async function storeRefreshToken(refresh_token: string) {
  sessionStorage.setItem('refresh_token', refresh_token);
}

export async function clearAccessToken() {
  sessionStorage.removeItem('access_token');
}

export async function clearRefreshToken() {
  sessionStorage.removeItem('refresh_token');
}
